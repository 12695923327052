import { useLayoutEffect } from 'react';

import { rsCounter, rsIncrement, rsInfrequent } from '../utils/rstats.client';
import { runAfterFramePaint } from '../utils/runAfterFramePaint';

/**
 * Easily measure the number of times a component re-rendered/evaluated, and how
 * long it took before the results were seen on screen..
 */
export function useRSCmp(name: string) {
  rsCounter(`paint-${name}-ms`)?.start();
  rsIncrement(`eval-cmp-${name}-c`);

  useLayoutEffect(() => {
    return runAfterFramePaint(() => {
      rsCounter(`paint-${name}-ms`)?.end();
      rsInfrequent(`paint-${name}-ms`, 3000);
    });
  });
}

import {
  type ReactNode,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useDecidedIsCoordinator } from '../../hooks/useMyInstance';
import { useReceivedVenueMode } from '../../hooks/useVenueMode';
import logger from '../../logger/logger';
import {
  ClientType,
  type Participant,
  SessionMode,
  VenueMode,
} from '../../types';
import {
  type AbortableRunner,
  YieldableAbortableRunner,
} from '../../utils/AbortSignalableRunner';
import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { Chan } from '../../utils/Chan';
import { Emitter } from '../../utils/emitter';
import { MonotonicallyIncrementingId } from '../../utils/MonotonicallyIncrementingId';
import { ChatMode } from '../Chat/common';
import { useClock } from '../Clock';
import { useCurrentSessionMode } from '../Game/hooks';
import { XIcon } from '../icons/XIcon';
import { useMyInstance, useParticipantsAsArray } from '../Player';
import { useSoundEffect } from '../SFX';
import {
  createWebRTCTestFailedListener,
  useMyClientType,
  useVenueEvent,
} from '../Venue';

type Id = string & { notifId: true };
function asNotifId(id: string): Id {
  return id as Id;
}

type AutodismissCtrl = {
  restart: () => void;
  cancel: () => void;
};

type ChatNotifItem = {
  id: Id;
  createdAt: number;
  content: ReactNode;
  stage: 'enter' | 'exit';
  opened: Promise<void>;
  closed: Promise<void>;
  markClosed: () => void;
  markOpened: () => void;
  autodismiss: AutodismissCtrl;
};

function isParticipantEnteredNotifExpired(
  now: () => number,
  expiry: Nullable<ETagish>
) {
  return expiry ? now() > expiry.expires : false;
}

type ETagish = Readonly<{ expires: number; tag: string }>;

function createParticipantEnteredExpiry(p: Participant, expiry = 5000) {
  return {
    expires: p.joinedAt + expiry,
    tag: p.clientId,
  };
}

class ActiveNotifs {
  private _actives: ChatNotifItem[] = [];

  readonly findIndex = Array.prototype.findIndex;
  readonly find = Array.prototype.find;

  constructor(private notifyExternal: (notifs: ChatNotifItem[]) => void) {
    this.requestStartClose = this.requestStartClose.bind(this);
    this.requestFinishOpen = this.requestFinishOpen.bind(this);
    this.requestFinishClose = this.requestFinishClose.bind(this);
  }

  private update(notifs: ChatNotifItem[]) {
    this._actives = notifs;
    if (this._actives === notifs) {
      this.notifyExternal([...this._actives]);
    } else {
      this.notifyExternal(this._actives);
    }
  }

  push(notif: ChatNotifItem) {
    this._actives.push(notif);
    this.update(this._actives);
  }

  async closeOldestUntilLimit(limit: number): Promise<Id[]> {
    if (this._actives.length <= limit) return [];
    const ids: Id[] = [];
    while (this._actives.length > limit) {
      const oldest = this._actives.shift();
      if (!oldest) continue;
      oldest.markOpened?.();
      oldest.markClosed?.();
      await oldest.opened;
      await oldest.closed;
      ids.push(oldest.id);
    }
    return ids;
  }

  requestStartClose(id: Id) {
    const idx = this._actives.findIndex((n) => n.id === id);
    if (idx === -1) return;
    const notif = this._actives.find((n) => n.id === id);
    if (!notif) return;
    notif.stage = 'exit';
    this._actives[idx] = { ...notif }; // mark as dirty via new reference/shallow copy
    this.update(this._actives);
  }

  requestFinishOpen(id: Id) {
    this._actives.find((n) => n.id === id)?.markOpened?.();
  }

  requestFinishClose(id: Id) {
    const idx = this._actives.findIndex((n) => n.id === id);
    if (idx === -1) return;
    const notif = this._actives[idx];
    if (!notif) return;
    notif.markClosed?.();
    this._actives.splice(idx, 1);
    this.update(this._actives);
  }
}

class UserEnteredStore {
  private idgen = new MonotonicallyIncrementingId('chat-notif-user-enter');
  private etags = new Map<Participant['clientId'], ETagish>();
  private ch = new Chan<Participant>();
  private max = 3;
  constructor(
    private now: () => number,
    notifyExternal: (notifs: ChatNotifItem[]) => void,

    public actives = new ActiveNotifs(notifyExternal)
  ) {}

  put(p: Participant) {
    this.ch.put(p);
  }

  async *process() {
    while (true) {
      const p: Awaited<ReturnType<(typeof this.ch)['take']>> =
        yield this.ch.take();
      if (!p) continue;

      let lastNotified = this.etags.get(p.clientId);

      if (!lastNotified) {
        lastNotified = createParticipantEnteredExpiry(p);
        this.etags.set(p.clientId, lastNotified);
        if (isParticipantEnteredNotifExpired(this.now, lastNotified)) continue;
      } else {
        continue;
      }

      // We are going to add one, so the max is actually (max - 1) to leave a new slot.
      await this.actives.closeOldestUntilLimit(this.max - 1);

      // add new

      const { promise: opened, resolve: markOpened } =
        Promise.withResolvers<void>();

      const { promise: closed, resolve: markClosed } =
        Promise.withResolvers<void>();

      const id = asNotifId(this.idgen.next());
      const createdAt = this.now();
      const content = (
        <span className='text-icon-gray text-sms italic'>
          {p.firstName ?? p.username} joined the event
        </span>
      );

      const autoCloseAfterMs = 8_000;
      const ctrl = new BrowserTimeoutCtrl();
      const restart = () =>
        ctrl.set(() => this.actives.requestStartClose(id), autoCloseAfterMs);
      restart();
      const cancel = ctrl.clear.bind(ctrl);

      const newest: ChatNotifItem = {
        id,
        createdAt,
        content,
        stage: 'enter' as const,
        opened,
        closed,
        markOpened,
        markClosed,
        autodismiss: { restart, cancel },
      };

      this.actives.push(newest);

      // pause the "machine" until the animation finishes for a nice effect.
      await newest.opened;
    }
  }
}

class UserTipsStore {
  private idgen = new MonotonicallyIncrementingId('chat-notif-tips');

  constructor(
    private now: () => number,
    notifyExternal: (notifs: ChatNotifItem[]) => void,
    public actives = new ActiveNotifs(notifyExternal)
  ) {}

  put(content: (ctrl: AutodismissCtrl) => ReactNode) {
    const { promise: opened, resolve: markOpened } =
      Promise.withResolvers<void>();

    const { promise: closed, resolve: markClosed } =
      Promise.withResolvers<void>();

    const id = asNotifId(this.idgen.next());

    const autoCloseAfterMs = 60_000;
    const ctrl = new BrowserTimeoutCtrl();
    const restart = () =>
      ctrl.set(() => this.actives.requestStartClose(id), autoCloseAfterMs);
    restart();
    const cancel = ctrl.clear.bind(ctrl);
    const autodismiss: AutodismissCtrl = { restart, cancel };

    const createdAt = this.now();
    this.actives.push({
      id,
      createdAt,
      content: content(autodismiss),
      stage: 'enter',
      opened,
      closed,
      markOpened,
      markClosed,
      autodismiss: { restart, cancel },
    });
  }
}

function useUserEnterNotifs() {
  const enabled = useFeatureQueryParam('chat-notifs-user-entered');
  const [log] = useState(() => logger.scoped('chat-notifs-user-enter'));

  const { now } = useClock();
  const me = useMyInstance();
  const participantsOkToNotif = useParticipantsAsArray({
    filters: ['status:connected', 'host:false', 'cohost:false', 'staff:false'],
    sorts: ['joinedAt:desc'],
  });

  // this is how the store notifies the component for rendering
  const [enteredNotifs, setEnteredNotifs] = useState<ChatNotifItem[]>(() => []);

  const [userEnteredStore] = useState(
    () => new UserEnteredStore(now, setEnteredNotifs)
  );

  useEffect(() => {
    if (!enabled) return;

    // push all participants for check if we need to notify.
    for (const participant of participantsOkToNotif) {
      // Ignore yourself
      if (participant.clientId === me?.clientId) continue;
      userEnteredStore.put(participant);
    }
  }, [enabled, me?.clientId, participantsOkToNotif, userEnteredStore]);

  useEffect(() => {
    if (!enabled) return;
    let runner: AbortableRunner<unknown, 'unmounting'>;

    async function exec() {
      try {
        runner = YieldableAbortableRunner(
          userEnteredStore.process.bind(userEnteredStore)
        );
        log.info('runner start');
        runner.start();
        const reason = await runner.finished;
        log.info('runner finished', { reason });
      } catch (reason) {
        log.error('runner died', { reason });
      }
    }

    exec();

    return () => {
      runner.destroy('unmounting');
    };
  }, [enabled, log, userEnteredStore]);

  return enteredNotifs.length > 0
    ? enteredNotifs.map((n) => (
        <ChatNotifNotification
          key={n.id}
          notification={n}
          requestFinishClose={userEnteredStore.actives.requestFinishClose}
          requestFinishOpen={userEnteredStore.actives.requestFinishOpen}
          requestStartClose={userEnteredStore.actives.requestStartClose}
        />
      ))
    : null;
}

function TimedTruncatable(props: {
  children: ReactNode;
  autodismissCtrl: AutodismissCtrl;
  className: string;
  truncateAfter?: number;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const isMounted = useIsMounted();

  // There is an implicit state machine here:
  // - 1. open: true, autodismiss is counting down
  // - 2. truncation timeout fires, open: false
  // - 3a. user clicks "see more", open: true, reset the autodismiss, reset the
  //    autotruncation. Goto 1.
  // - 3b. autodismiss fires, entire notif is closed

  const [open, setOpen] = useState(true);
  const [truncateIteration, resetTruncation] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (truncateIteration < 0) return;
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(
      () => (isMounted() ? setOpen(false) : null),
      props.truncateAfter ?? 20_000
    );
    return () => ctrl.clear();
  }, [isMounted, props.truncateAfter, truncateIteration]);

  const truncatedTextContent = useRef<null | string>(null);
  useEffect(() => {
    if (!ref.current || !open) return;
    // Use the actual DOM to get the content with most of the whitespace, but
    // none of the elements.
    truncatedTextContent.current = ref.current.innerText;
  }, [open]);

  const onSeeMore = () => {
    props.autodismissCtrl.restart();
    setOpen(true);
    resetTruncation();
  };

  return (
    <div className={props.className} ref={ref}>
      {open ? (
        props.children
      ) : (
        <div
          style={{
            // truncate by number of lines
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
            display: '-webkit-box',
          }}
        >
          {truncatedTextContent.current}
        </div>
      )}
      {open ? null : (
        <button type='button' className='block underline' onClick={onSeeMore}>
          See More
        </button>
      )}
    </div>
  );
}

function useUserTipsNotifs(visible: boolean) {
  const enabled = useFeatureQueryParam('chat-notifs-user-tips');
  const me = useMyInstance();
  const { now } = useClock();

  const [tipsNotifs, setTipsNotifs] = useState<ChatNotifItem[]>(() => []);
  const [tipsStore] = useState(() => new UserTipsStore(now, setTipsNotifs));

  const sessionMode = useCurrentSessionMode();
  const isCoordinator = useDecidedIsCoordinator();
  const venueMode = useReceivedVenueMode();
  const clientType = useMyClientType();
  const vEvent = useVenueEvent();

  const msgLive01 = useLiveCallback(() => {
    tipsStore.put((autodismissCtrl) => (
      <TimedTruncatable
        className='text-white text-sms'
        autodismissCtrl={autodismissCtrl}
      >
        <p className='font-bold'>
          👋 Welcome {vEvent?.orgName ?? me?.firstName ?? me?.username}
        </p>
        <p>
          Just message us when your group is ready. Otherwise we'll start
          shortly!
        </p>
      </TimedTruncatable>
    ));
  });

  const msgLive02 = useLiveCallback(() => {
    tipsStore.put((autodismissCtrl) => (
      <TimedTruncatable
        className='text-white text-sms'
        autodismissCtrl={autodismissCtrl}
      >
        <p>A few tips:</p>
        <ul className='list-disc pl-4'>
          <li>Turn off Zoom, MS Teams, and similar apps</li>
          <li>Volume and camera controls are on the left</li>
          <li>Use headphones if possible</li>
          <li>Late joiners will be auto-assigned to teams</li>
          <li>Support is available via the help button on the left</li>
        </ul>
        <p>Enjoy!</p>
      </TimedTruncatable>
    ));
  });

  const msgOndCoordinator = useLiveCallback(() => {
    tipsStore.put((autodismissCtrl) => (
      <TimedTruncatable
        className='text-white text-sms'
        autodismissCtrl={autodismissCtrl}
      >
        <p className='font-bold'>👋 Welcome {me?.firstName ?? me?.username}</p>
        <p>A few tips:</p>
        <ul className='list-disc pl-4'>
          <li>
            When your group has joined, click Start to randomize everyone into
            teams
          </li>
          <li>Late joiners will be auto-assigned to teams</li>
          <li>Turn off Zoom, MS Teams, and similar apps</li>
          <li>Volume and camera controls are on the left</li>
          <li>Use headphones if possible</li>
          <li>Support is available via the help button on the left</li>
        </ul>
        <p>Enjoy!</p>
      </TimedTruncatable>
    ));
  });

  const msgOndParticipant = useLiveCallback(() => {
    tipsStore.put((autodismissCtrl) => (
      <TimedTruncatable
        className='text-white text-sms'
        autodismissCtrl={autodismissCtrl}
      >
        <p className='font-bold'>👋 Welcome {me?.firstName ?? me?.username}</p>
        <p>A few tips:</p>
        <ul className='list-disc pl-4'>
          <li>Turn off Zoom, MS Teams, and similar apps</li>
          <li>Volume and camera controls are on the left</li>
          <li>Use headphones if possible</li>
          <li>Support is available via the help button on the left</li>
        </ul>
        <p>Enjoy!</p>
      </TimedTruncatable>
    ));
  });

  const hasStartedNotifStages = useRef(false);

  useEffect(() => {
    if (
      venueMode === null ||
      venueMode === VenueMode.Game ||
      isCoordinator === null ||
      clientType === ClientType.Host ||
      hasStartedNotifStages.current === true ||
      !enabled
    )
      return;

    // Only run these stages once per mount / load.
    hasStartedNotifStages.current = true;

    const ctrl01 = new BrowserTimeoutCtrl();
    const ctrl02 = new BrowserTimeoutCtrl();

    const stage01Ms = 3_000;
    const stage02Ms = 30_000;

    ctrl01.set(() => {
      if (sessionMode === SessionMode.Live) msgLive01();
      else if (sessionMode === SessionMode.OnDemand) {
        if (isCoordinator) msgOndCoordinator();
        else msgOndParticipant();
      }
    }, stage01Ms);

    ctrl02.set(() => {
      if (sessionMode === SessionMode.Live) msgLive02();
    }, stage02Ms);

    return () => {
      ctrl01.clear();
      ctrl02.clear();
    };
  }, [
    clientType,
    enabled,
    isCoordinator,
    msgLive01,
    msgLive02,
    msgOndCoordinator,
    msgOndParticipant,
    sessionMode,
    venueMode,
  ]);

  return tipsNotifs.length > 0
    ? tipsNotifs.map((n) => (
        <ChatNotifNotification
          key={n.id}
          notification={n}
          requestFinishClose={tipsStore.actives.requestFinishClose}
          requestFinishOpen={tipsStore.actives.requestFinishOpen}
          requestStartClose={tipsStore.actives.requestStartClose}
          withSoundEffect={visible}
        />
      ))
    : null;
}

function useWebRTCFailedNotifs() {
  const [notifItem, setNotifItem] = useState<ChatNotifItem | null>(null);
  const clock = useClock();
  useLayoutEffect(() => {
    const off = createWebRTCTestFailedListener(() => {
      const { promise: opened, resolve: markOpened } =
        Promise.withResolvers<void>();

      const { promise: closed, resolve: markClosed } =
        Promise.withResolvers<void>();
      const autodismiss = {
        restart: () => void 0,
        cancel: () => void 0,
      };
      const createdAt = clock.now();
      setNotifItem({
        id: asNotifId(`webrtc-failed-${createdAt}`),
        createdAt,
        content: (
          <div className='text-white text-sms flex flex-col gap-3'>
            <div className='font-bold'>
              ⚠️ Critical: Connection Problem Detected
            </div>
            <div>
              We’ve detected a connection issue that may negatively affect your
              Luna Park experience.
            </div>
            <div>
              This could be related to a VPN, firewall, or other network
              problems. Please disable your VPN to enjoy an uninterrupted
              experience.
            </div>
            <div>
              If you are unfamiliar with VPN please tap the HELP button on the
              left.
            </div>
          </div>
        ),
        stage: 'enter',
        opened,
        closed,
        markOpened,
        markClosed,
        autodismiss,
      });
    });
    return off;
  }, [clock]);

  const requestFinishClose = useLiveCallback(() => setNotifItem(null));
  const requestFinishOpen = useLiveCallback(() => void 0);
  const requestStartClose = useLiveCallback(() => {
    setNotifItem((prev) => {
      if (!prev) return prev;
      prev.stage = 'exit';
      return { ...prev };
    });
  });

  if (!notifItem) return null;

  return (
    <ChatNotifNotification
      key={notifItem.id}
      notification={notifItem}
      requestFinishClose={requestFinishClose}
      requestFinishOpen={requestFinishOpen}
      requestStartClose={requestStartClose}
      containerClassName='!bg-lp-red-002 !text-white'
      withSoundEffect
    />
  );
}

type ExtChatNotifPayload = {
  notifContent: ReactNode;
  withSoundEffect?: boolean;
  containerClassName?: string;
  autoCloseAfterMs?: number;
};

class ExtNotifsStore {
  private idgen = new MonotonicallyIncrementingId('chat-notif-ext');
  private ch = new Chan<ExtChatNotifPayload>();
  private max = 3;
  constructor(
    private now: () => number,
    notifyExternal: (notifs: ChatNotifItem[]) => void,

    private actives = new ActiveNotifs(notifyExternal),
    private props = new Map<Id, Omit<ExtChatNotifPayload, 'notifContent'>>()
  ) {}

  put(p: ExtChatNotifPayload) {
    this.ch.put(p);
  }

  async *process() {
    while (true) {
      const p: Awaited<ReturnType<(typeof this.ch)['take']>> =
        yield this.ch.take();
      if (!p) continue;

      // We are going to add one, so the max is actually (max - 1) to leave a new slot.
      await this.actives.closeOldestUntilLimit(this.max - 1);

      // add new

      const { promise: opened, resolve: markOpened } =
        Promise.withResolvers<void>();

      const { promise: closed, resolve: markClosed } =
        Promise.withResolvers<void>();

      const id = asNotifId(this.idgen.next());
      const createdAt = this.now();

      const autoCloseAfterMs = p.autoCloseAfterMs ?? 8_000;
      const ctrl = new BrowserTimeoutCtrl();
      const restart = () =>
        ctrl.set(() => this.actives.requestStartClose(id), autoCloseAfterMs);
      restart();
      const cancel = ctrl.clear.bind(ctrl);
      this.props.set(id, {
        withSoundEffect: p.withSoundEffect,
        containerClassName: p.containerClassName,
      });

      const newest: ChatNotifItem = {
        id,
        createdAt,
        content: p.notifContent,
        stage: 'enter' as const,
        opened,
        closed,
        markOpened,
        markClosed,
        autodismiss: { restart, cancel },
      };

      this.actives.push(newest);

      // pause the "machine" until the animation finishes for a nice effect.
      await newest.opened;
    }
  }

  requestStartClose(id: Id) {
    this.actives.requestFinishClose(id);
  }

  requestFinishOpen(id: Id) {
    this.actives.requestFinishOpen(id);
  }

  requestFinishClose(id: Id) {
    this.actives.requestFinishClose(id);
    this.props.delete(id);
  }

  withSoundEffect(id: Id) {
    return this.props.get(id)?.withSoundEffect;
  }

  containerClassName(id: Id) {
    return this.props.get(id)?.containerClassName;
  }
}

export type ExtChatNotifsEvents = {
  'add-chat-notifis': (payload: ExtChatNotifPayload) => void;
};

const emitter = new Emitter<ExtChatNotifsEvents>();

export function useExtSendChatNotifs() {
  return useLiveCallback((payload: ExtChatNotifPayload) => {
    emitter.emit('add-chat-notifis', payload);
  });
}

function useExtEmittedNotifs() {
  const [items, setItems] = useState<ChatNotifItem[]>(() => []);
  const { now } = useClock();
  const [store] = useState(() => new ExtNotifsStore(now, setItems));
  const [log] = useState(() => logger.scoped('chat-notifs-ext'));

  useEffect(() => {
    return emitter.on('add-chat-notifis', (payload) => {
      store.put(payload);
    });
  });

  useEffect(() => {
    let runner: AbortableRunner<unknown, 'unmounting'>;

    async function exec() {
      try {
        runner = YieldableAbortableRunner(store.process.bind(store));
        log.info('runner start');
        runner.start();
        const reason = await runner.finished;
        log.info('runner finished', { reason });
      } catch (reason) {
        log.error('runner died', { reason });
      }
    }

    exec();

    return () => {
      runner.destroy('unmounting');
    };
  }, [log, store]);

  return items.length > 0
    ? items.map((n) => (
        <ChatNotifNotification
          key={n.id}
          notification={n}
          requestFinishClose={(id) => store.requestFinishClose(id)}
          requestFinishOpen={(id) => store.requestFinishOpen(id)}
          requestStartClose={(id) => store.requestStartClose(id)}
          withSoundEffect={store.withSoundEffect(n.id)}
          containerClassName={store.containerClassName(n.id)}
        />
      ))
    : null;
}

export function ChatNotifsContainer(props: { chatMode: ChatMode }) {
  const tips = useUserTipsNotifs(props.chatMode !== ChatMode.None);
  const enters = useUserEnterNotifs();
  const webrtcFailed = useWebRTCFailedNotifs();
  const exts = useExtEmittedNotifs();

  const hasNotifs = !!(tips || enters || webrtcFailed || exts);

  return hasNotifs ? (
    <div
      className={`w-full ${
        props.chatMode === ChatMode.Preview ? '' : 'px-2.5'
      } flex flex-col gap-0.5 ${
        props.chatMode === ChatMode.None ? 'hidden' : ''
      }`}
    >
      {tips}
      {enters}
      {webrtcFailed}
      {exts}
    </div>
  ) : null;
}

async function execAnimation(
  stage: ChatNotifItem['stage'],
  ref: HTMLDivElement,
  aborter: AbortSignal,
  enabled: { [K in ChatNotifItem['stage']]: boolean },
  onEntered: () => void,
  onExited: () => void
) {
  let anim: Animation | undefined;

  aborter.addEventListener('abort', () => {
    anim?.finish();
  });

  if (stage === 'enter') {
    if (!enabled.enter) return;

    anim = ref.animate(
      [{ transform: 'translateX(100%)' }, { transform: 'translateX(0)' }],
      {
        duration: 100,
        easing: 'ease-in-out',
        fill: 'both',
      }
    );

    anim.play();
    await anim?.finished;
    onEntered();
  } else if (stage === 'exit') {
    if (!enabled.exit) {
      onExited();
      return;
    }

    anim = ref.animate(
      [{ transform: 'translateX(0)' }, { transform: 'translateX(100%)' }],
      {
        duration: 100,
        easing: 'ease-in-out',
        fill: 'both',
      }
    );

    anim.play();
    await anim?.finished;
    onExited();
  }
}

function ChatNotifNotification(props: {
  notification: ChatNotifItem;
  requestFinishOpen: (id: Id) => void;
  requestStartClose: (id: Id) => void;
  requestFinishClose: (id: Id) => void;
  withSoundEffect?: boolean;
  containerClassName?: string;
}) {
  const ref = useRef<null | HTMLDivElement>(null);

  const {
    requestFinishOpen,
    requestFinishClose,
    notification: { id, stage },
    withSoundEffect,
  } = props;

  useLayoutEffect(() => {
    const aborter = new AbortController();

    if (!stage || !ref.current) return;

    execAnimation(
      stage,
      ref.current,
      aborter.signal,
      {
        enter: true,
        exit: false,
      },
      () => requestFinishOpen(id),
      () => requestFinishClose(id)
    );

    return () => {
      aborter.abort();
    };
  }, [id, requestFinishClose, requestFinishOpen, stage]);

  const attemptedPlay = useRef(false);
  const { play } = useSoundEffect('scoreboardScoreDeltaAppear');
  useEffect(() => {
    if (withSoundEffect && !attemptedPlay.current) play();
    attemptedPlay.current = true;
  }, [play, withSoundEffect]);

  return (
    <div
      ref={ref}
      className={`w-full bg-lp-system px-4.5 py-3 rounded-lg flex justify-between text-icon-gray ${
        props.containerClassName ?? ''
      }`}
    >
      {props.notification.content}
      <button
        className='self-start'
        type='button'
        onClick={() => props.requestStartClose(props.notification.id)}
      >
        <XIcon />
      </button>
    </div>
  );
}

import React, { type ReactNode, useContext, useMemo, useState } from 'react';

interface ChatSharedContext {
  chatInited: boolean;
  setChatInited: (val: boolean) => void;
  chatToClientId: string | null;
  setChatToClientId: (clientId: string | null) => void;
}

const Context = React.createContext<ChatSharedContext | null>(null);

export function useChatSharedContext(): ChatSharedContext {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('ChatSharedContext is not in the tree!');
  }
  return ctx;
}

export const ChatSharedContextProvider = (props: {
  children?: ReactNode;
}): JSX.Element => {
  const [chatInited, setChatInited] = useState(false);
  const [chatToClientId, setChatToClientId] = useState<string | null>(null);
  const ctxValue = useMemo(
    () => ({ chatInited, setChatInited, chatToClientId, setChatToClientId }),
    [chatInited, setChatInited, chatToClientId, setChatToClientId]
  );
  return <Context.Provider value={ctxValue}>{props.children}</Context.Provider>;
};

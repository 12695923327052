import { useEffect, useState } from 'react';
import { useEffectOnce, usePreviousDistinct } from 'react-use';

import { useMyInstance } from '../../hooks/useMyInstance';
import { useIsUnloading } from '../../hooks/useUnload';
import { safeWindowReload } from '../../logger/logger';
import { ClientTypeUtils } from '../../types';
import { Modal } from '../common/Modal';
import { Loading } from '../Loading';
import { useMyClientType } from '../Venue/VenuePlaygroundProvider';
import { useFirebaseContext } from './Context';
import { log } from './shared';
import { FirebaseStatus } from './types';

function DisconnectedModal(): JSX.Element {
  useEffectOnce(() => {
    log.info('disconnected modal activated');
  });
  const handleReconnect = (): void => {
    safeWindowReload();
  };
  return (
    <Modal borderStyle='gray'>
      <div className='w-85 flex flex-col justify-start items-center text-white py-5 px-2'>
        <div className='text-2xl font-medium'>You are disconnected</div>
        <div className='text-sms text-center w-5/6 mt-2 mb-4'>
          The connection to Luna Park has been interrupted! Please click on the
          button below to refresh and join again.
        </div>
        <button className='btn-primary w-33 h-10' onClick={handleReconnect}>
          Reconnect
        </button>
      </div>
    </Modal>
  );
}

function ReconnectingModal() {
  useEffectOnce(() => {
    log.info('reconnecting modal activated');
  });
  return (
    <Modal borderStyle='gray'>
      <div className='w-85 flex flex-col justify-start items-center text-white py-5 px-2'>
        <div className='text-2xl font-medium'>Attempting to Reconnect</div>
        <div className='text-sms text-center w-5/6 mt-2 mb-4'>
          Your connection to your Team has been interrupted, and we are
          reconnecting you to Luna Park.
        </div>
        <Loading text='' imgClassName='w-8 h-8' />
      </div>
    </Modal>
  );
}

// Note(jialin): This happens in the following cases,
// 1. The venue is reset by host, which force removing the users from venue even
//    if they are still connected.
// 2. When Firebase gets fully disconnected, Redux Store
//    (and a few other components) will be disposed, so the participantSlice
//    will be cleared, myInstance is removed too.
// 3. In the local dev, React HMR reloads the app when code changes.
function useCheckParticipantRemoved(): boolean {
  const myClientType = useMyClientType();
  const me = useMyInstance();
  const clientId = me?.clientId;
  const prevClientId = usePreviousDistinct(clientId);
  const [participantRemoved, setParticipantRemoved] = useState(false);

  useEffect(() => {
    if (ClientTypeUtils.isHost(myClientType)) return;
    setParticipantRemoved(!!prevClientId && !clientId);
  }, [clientId, prevClientId, myClientType]);

  return participantRemoved;
}

export function FirebaseReconnectModal(): JSX.Element | null {
  const { status } = useFirebaseContext();
  const removed = useCheckParticipantRemoved();
  const isUnloading = useIsUnloading();

  if (isUnloading) return null;

  if (status === FirebaseStatus.Connecting) {
    return <ReconnectingModal />;
  } else if (status === FirebaseStatus.Disconnected) {
    return <DisconnectedModal />;
  }

  if (removed) return <DisconnectedModal />;
  return null;
}

import { createContext, type ReactNode, useContext, useMemo } from 'react';

export enum PlaybackMode {
  Live = 'live',
  Recorded = 'recorded',
}

type PlaybackModeAPI = {
  mode: PlaybackMode;
};

const PlaybackModeContext = createContext<null | PlaybackModeAPI>(null);

export const usePlaybackMode = (): PlaybackModeAPI => {
  const ctx = useContext(PlaybackModeContext);
  if (!ctx) throw new Error('No value for PlaybackModeContext');
  return ctx;
};

export function PlaybackModeProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const ctx: PlaybackModeAPI = useMemo(
    () => ({
      mode: PlaybackMode.Live,
    }),
    []
  );

  return (
    <PlaybackModeContext.Provider value={ctx}>
      {props.children}
    </PlaybackModeContext.Provider>
  );
}

import { useEffect } from 'react';
import { useLatest } from 'react-use';
import useSWRImmutable from 'swr/immutable';

import { EnumsWebRTCTestSource } from '@lp-lib/api-service-client/public';

import { Clock } from '../../components/Clock';
import { isGuest, useUser } from '../../components/UserContext';
import { getFeatureQueryParamNumber } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useIsCoordinator } from '../../hooks/useMyInstance';
import logger from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { type WebRTCTestResult, WebRTCTestRunner } from '../../services/webrtc';
import { getStaticAssetPath } from '../../utils/assets';
import { sleep, uuidv4 } from '../../utils/common';
import { StorageFactory } from '../../utils/storage';
import { useVenueOwner } from './VenueProvider';

const STORAGE_KEY = 'webRTCTestResult';

type WebRTCTestCachedResult = {
  succeeded: boolean;
  timstamp: number;
};

class VenueWebRTCTestRunner {
  constructor(
    readonly log = logger.scoped('venue-webrtc-test'),
    readonly storage = StorageFactory<
      typeof STORAGE_KEY,
      WebRTCTestCachedResult
    >('session'),
    private deps = {
      now: Clock.instance().now,
    }
  ) {}

  async start(
    maxRounds: number,
    waitMs: number
  ): Promise<true | WebRTCTestResult[]> {
    const cached = this.storage.get(STORAGE_KEY);
    this.log.info('check if we should run WebRTC test', {
      result: cached,
    });
    if (cached?.succeeded) {
      this.log.info('detected previous successful WebRTC test, skip');
      return true;
    }
    const results: WebRTCTestResult[] = [];
    for (let i = 0; i < maxRounds; i++) {
      const round = i + 1;
      try {
        const result = await this.execute(uuidv4(), round);
        results.push(result);
        if (result.succeeded) {
          break;
        }
      } catch (error) {
        this.log.error('failed to run WebRTC test', { error, round });
      }
      if (i < maxRounds - 1) {
        this.log.info('WebRTC test, wait before next round', { waitMs });
        await sleep(waitMs);
      }
    }
    if (results.length === 0) {
      this.log.warn('no WebRTC test results', { maxRounds });
      return [];
    }
    const result = results[results.length - 1];
    this.storage.set(STORAGE_KEY, {
      succeeded: result.succeeded,
      timstamp: this.deps.now(),
    });
    return results;
  }

  private async execute(id: string, round: number) {
    const runner = new WebRTCTestRunner({
      id,
      timeout: getFeatureQueryParamNumber(
        'connection-test-in-venue-timeout-ms'
      ),
      mediaSource: getStaticAssetPath('audios/connection-test.aac'),
      mediaType: 'audio',
    });

    this.log.info('start WebRTC test', { testId: id, round });
    const result = await runner.run();
    this.log.info(`WebRTC connectivity, succeeded: ${result.succeeded}`, {
      testId: id,
      succeeded: result.succeeded,
      round,
    });
    return result;
  }
}

export function WebRTCTest() {
  const user = useUser();
  const email = isGuest(user) ? '' : user.email;
  const venueOwner = useVenueOwner();
  const isCoordinator = useLatest(useIsCoordinator());

  const { data: org, isLoading } = useSWRImmutable(
    'webrtc-test-org',
    async () => {
      if (user.organizer?.organization) return user.organizer.organization;
      if (!venueOwner.orgId) return null;
      const resp = await apiService.organization.getOrganization(
        venueOwner.orgId
      );
      return resp.data.organization;
    }
  );

  const orgTestEnabled = !org?.settings?.venueConnectivityTestDisabled;

  const runTest = useLiveCallback(() => {
    const maxRounds = getFeatureQueryParamNumber('connection-test-in-venue');
    const roundWaitMs = getFeatureQueryParamNumber(
      'connection-test-in-venue-wait-ms'
    );
    const runner = new VenueWebRTCTestRunner();
    if (maxRounds === 0) {
      runner.log.info('WebRTC test is disabled by feature flag');
      return;
    }
    if (!orgTestEnabled) {
      runner.log.info('WebRTC test is disabled by organization setting', {
        orgId: org.id,
      });
      return;
    }
    runner.start(maxRounds, roundWaitMs).then(async (results) => {
      if (results === true) return;
      const result = results[results.length - 1];
      if (result) {
        await apiService.misc.reportTestResult({
          id: result.id,
          from: window.location.href,
          source: EnumsWebRTCTestSource.WebRTCTestSourceVenue,
          email: email ?? '',
          orgId: org?.id,
          isGameCoordinator: isCoordinator.current,
          results,
        });
      }
      const succeeded = !!result && result.succeeded;
      if (succeeded) return;
      const event = new Event('webrtc-test-failed');
      document.dispatchEvent(event);
    });
  });

  useEffect(() => {
    if (isLoading) return;
    runTest();
  }, [isLoading, runTest]);

  return null;
}

export function createWebRTCTestFailedListener(listener: (evt: Event) => void) {
  document.addEventListener('webrtc-test-failed', listener);
  return () => document.removeEventListener('webrtc-test-failed', listener);
}

import { useEffect } from 'react';

/**
 * Add noop listeners for mediaSession actions, such as media key controls
 * (play, pause, next, etc). Luna Park plays a _lot_ of videos and audio, and
 * the latest will automatically become the focus of the user's media keys.
 */
export function useInterceptNavigatorMediaSessionKeys(): void {
  useEffect(() => {
    if (!('mediaSession' in navigator)) return;
    navigator.mediaSession.metadata = null;

    const noop = () => void 0;

    const actions = new Set<MediaSessionAction>([
      'play',
      'pause',
      'stop',
      'seekbackward',
      'seekforward',
      'seekto',
      'previoustrack',
      'nexttrack',
      'skipad',
    ]);

    const setAll = (value: null | (() => void)) => {
      for (const action of actions) {
        try {
          navigator.mediaSession.setActionHandler(action, value);
        } catch (err) {
          // We don't care if the action fails or is unsupported.
        }
      }
    };

    setAll(noop);

    return () => setAll(null);
  }, []);
}
